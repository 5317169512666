<script setup lang="ts">
import { useVuelidate } from '@vuelidate/core'

import { required, email } from '@vuelidate/validators'

import { useModal } from '~/stores/modal'

const { CONTACT } = useGeneralService()

const acceptPolice = ref(false)

const formData = reactive({
    email: '',
    subject: '',
    message: '',
    name: '',
    last_name: '',
})

const formValidations = computed(() => {
    return {
        email: { email, required },
        subject: { required },
        message: { required },
        name: { required },
        last_name: { required },
    }
})

const v$ = useVuelidate(formValidations, formData)

const validationMessages = computed(() => ({
    email:
        v$.value.email.required.$invalid && v$.value.$dirty
            ? '*El email es requerido*'
            : v$.value.email.email.$invalid && v$.value.$dirty
            ? '*Debe ser un email valido*'
            : false,
    subject: v$.value.subject.required.$invalid && v$.value.$dirty ? '*El asunto es requerido*' : false,
    name: v$.value.name.required.$invalid && v$.value.$dirty ? '*El nombre es requerido*' : false,
    last_name: v$.value.last_name.required.$invalid && v$.value.$dirty ? '*El apellido es requerido*' : false,
    message: v$.value.message.required.$invalid && v$.value.$dirty ? '*El mensaje es requerido*' : false,
}))

const isLoading = ref(false)

const submitForm = async () => {
    const ModalStore = useModal()

    if (!acceptPolice.value) {
        ModalStore.setModal({
            name: 'ModalDefault',
            info: {
                text: 'Debe estar de acuerdo con las políticas de privacidad de Arte Milenio.',
                continue: {
                    handler: () => null,
                    text: 'Cerrar',
                },
            },
        })
        return
    }

    v$.value.$touch()

    if (v$.value.$invalid) return

    isLoading.value = true

    try {
        const response = await CONTACT(formData)

        if (response?.data) {
            formData.email = ''
            formData.name = ''
            formData.last_name = ''
            formData.subject = ''
            formData.message = ''
            acceptPolice.value = false

            v$.value.$reset()
            ModalStore.setModal({
                name: 'ModalDefault',
                info: {
                    text: response.data.message,
                    continue: {
                        handler: () => {
                            window.scrollTo({
                                top: 0,
                            })
                        },
                        text: 'Cerrar',
                    },
                },
            })
        }
    } catch (e: any) {
        ModalStore.setModal({
            name: 'ModalDefault',
            info: {
                text: 'Presentamos fallas al procesar su solicitud',
                continue: {
                    handler: () => null,
                    text: 'Intentar nuevamente',
                },
            },
        })

        console.log('Contact error: ', { e })
    }

    isLoading.value = false
}
</script>

<template>
    <section id="formulario-de-contacto" class="contact">
        <div class="contact__wrapper">
            <h2 class="contact__title">Contáctanos</h2>
            <form class="contact__form" id="form" @submit.prevent="submitForm">
                <section>
                    <div>
                        <label for="contact-form-name">Nombre</label>
                        <input id="contact-form-name" type="text" placeholder="" v-model="formData.name" />
                        <UtilsFormValidation
                            class="error"
                            v-if="validationMessages.name"
                            :validation="validationMessages.name"
                        />
                    </div>
                    <div>
                        <label for="contact-form-lastname">Apellido</label>
                        <input
                            id="contact-form-lastname"
                            type="text"
                            placeholder=""
                            v-model="formData.last_name"
                        />
                        <UtilsFormValidation
                            class="error"
                            v-if="validationMessages.last_name"
                            :validation="validationMessages.last_name"
                        />
                    </div>
                    <div>
                        <label for="contact-form-email">Email</label>
                        <input id="contact-form-email" type="email" placeholder="" v-model="formData.email" />
                        <UtilsFormValidation
                            class="error"
                            v-if="validationMessages.email"
                            :validation="validationMessages.email"
                        />
                    </div>
                    <div>
                        <label for="contact-form-subject">Asunto</label>
                        <input
                            id="contact-form-subject"
                            type="text"
                            placeholder=""
                            v-model="formData.subject"
                        />
                        <UtilsFormValidation
                            class="error"
                            v-if="validationMessages.subject"
                            :validation="validationMessages.subject"
                        />
                    </div>
                    <div>
                        <label for="contact-form-message">Mensaje</label>
                        <input
                            id="contact-form-message"
                            type="text"
                            placeholder=""
                            v-model="formData.message"
                        />
                        <UtilsFormValidation
                            class="error"
                            v-if="validationMessages.message"
                            :validation="validationMessages.message"
                        />
                    </div>
                </section>
                <section>
                    <input v-model="acceptPolice" type="checkbox" id="contact-form-check" />
                    <label for="contact-form-check"
                        >Acepto el Aviso de Privacidad de
                        <a
                            class="underline text-site-primary"
                            href="https://www.milenio.com/aviso-de-privacidad"
                            target="_blank"
                            >Milenio.com</a
                        ></label
                    >
                </section>
                <button type="submit">Enviar</button>
            </form>
        </div>
    </section>
</template>

<style lang="postcss" scoped>
.contact {
    @apply bg-[#F4F4F4] py-10;
    &__wrapper {
        @apply container gap-10 lg:my-10;
    }
    &__title {
        @apply font-Pathway font-extrabold text-[27px] lg:text-[43px] lg:pb-5;
    }
    &__form {
        @apply space-y-5;
        & section:first-of-type {
            @apply flex flex-wrap gap-x-5 lg:justify-center gap-y-5 my-5 lg:my-0;
            & div {
                @apply w-full space-y-2;
                & label {
                    @apply text-black font-medium font-Inter text-sm;
                }
                & input {
                    @apply w-full border border-black bg-white ring-black;
                }
            }
            & div:not(:last-of-type) {
                @apply lg:basis-[calc(50%-0.625rem)];
            }
            & div:last-of-type {
                @apply w-full;
            }
        }
        & section:last-of-type {
            @apply flex gap-2 pt-3 pb-5 items-center;
            & label {
                @apply font-medium font-Inter text-sm lg:text-base text-[#6B6B6B];
            }
            & input {
                @apply w-5 h-5 text-black ring-0;
            }
        }
        & button {
            @apply w-full lg:w-fit py-3 px-14 bg-black font-Inter font-semibold text-lg text-white;
        }
    }
}
</style>
